import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RefDataService } from 'src/app/core/services/ref-data.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormBuilder, FormGroup } from '@angular/forms';

/**
 * Component
 */
@Component({
    selector: 'app-ref-data',
    templateUrl: './ref-data.component.html',
    styleUrls: ['./ref-data.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class RefDataComponent implements OnInit, OnDestroy {
    public loading = false;
    tableList: any;
    tableModel: any;

    gridData: any;
    pageSize: number = 100;
    skip: number = 0;

    constructor(
        private alertService: AlertService,
        private refDataService: RefDataService,
        private router: Router,
        private authService: AuthenticationService,
        private modalService: NgbModal,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        if (!this.authService.isPrivacyNoticeAcknowledged) {
            this.router.navigate(['/privacy-notice']);
        }
        this.tableModel = { tableId: '', tableDisplayName: '', pageNumber: 1, pageSize: this.pageSize, isInitialLoad: true, sortList: [], filter: [] }
        this.getTableList();
    }

    ngOnDestroy() {
        this.modalService.dismissAll();
    }

    private getTableList() {
        this.loading = true;
        this.refDataService.getTableList().then((tableList: any[]) => {
            this.tableList = tableList.map(x => ({ tableId: x.RefDataTableID, displayName: x.RefDataTableName }));
            this.tableModel.tableId = this.tableList[0].tableId;
            this.tableModel.tableDisplayName = this.tableList[0].displayName;
            this.loading = false;
        }).catch(() => { this.loading = false; });
    }

    getTableResult(isInitialLoad: boolean, applySelection: boolean) {
        this.loading = true;
        this.tableModel.pageNumber = (this.skip / this.pageSize) + 1;
        this.tableModel.isInitialLoad = isInitialLoad;

        if (!applySelection) {
            this.tableModel.filter = [];
            this.tableModel.sortList = [];
            this.tableModel.pageNumber = 1;
        }

        if (this.tableModel.tableId === null) {
            this.alertService.error('Please select a table!');
            return;
        }
        else this.tableModel.tableDisplayName = this.tableList.filter(x => x.tableId == this.tableModel.tableId)[0].displayName;

        this.loading = true;
        this.refDataService.getTableResult(this.tableModel).then((tableResult: any) => {
            this.gridData = {
                data: JSON.parse(JSON.stringify(tableResult.Data)),
                total: tableResult.TotalCount,
                columns: tableResult.Columns
            };
            this.loading = false;
        }).catch(() => { 
            this.loading = false; });
    }

    pageChange(event: PageChangeEvent) {
        this.skip = event.skip;
        this.getTableResult(false, true);
    }

    downloadTableResult() {
        this.alertService.info('The table result will be downloaded in a while! Please note that the selected filter and sort criteria will not be applied in the downloaded file.');
        this.refDataService.downloadTableResult(this.tableModel);
    }

    updateTableModel(dynamicModel)
    {
        this.skip = dynamicModel.skip;
        this.tableModel.isInitialLoad = false;
        this.tableModel.pageNumber = dynamicModel.pageNumber;
        this.tableModel.sortList = dynamicModel.sortList;
        this.tableModel.filter = dynamicModel.filter;
        this.getTableResult(false, true)
    }

    
    async saveRefDatachanges(formChanges: any) {
        if (formChanges.length > 0) {
            this.loading = true;
            await this.refDataService.saveRefDataTablechanges(this.tableModel.tableId, formChanges)
                .then((model: any) => {
                    this.getTableResult(false, true);
                    this.alertService.info("Record Saved successfully!");
                }).catch(() => { this.loading = false; });
        }
        else {
            this.alertService.info('No changes to save!');
        }
    }

    downloadAuditReport() {
        this.alertService.info('The audit report will be downloaded in a while. Please note that only saved changes will be downloaded!');
        this.refDataService.downloadFormAuditReport(this.tableModel);
    }
    
    
}
