import { Component, OnInit, OnChanges, ViewEncapsulation, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { UserManagementService } from 'src/app/core/services/user.service';
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { AlertService } from 'src/app/core/services/alert.service';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';


/**
 * Component
 */
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class UserManagementComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  newUser = false;

  public loading = true;
  public roleList: string;
  public cols: any[];
  public dataUserFiles: any[];
  public users: any[];
  public roles: any[];
  public userCols: any[];
  private username = "";
  private email = "";
  private userid = '';
  private isactive = false;
  private displayUser = true;
  private selectedRoles: any = [];
  private selectedUser: any = [];
  private mode = '';
  private loggedInUser: any;
  private selectedUserRow: any;
  private roleWarningMessage = true;
  promptResponse: Subscription;
  isActiveFilter: any = [];

  /**
   * Creates an instance of users list component.
   */
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthenticationService,
    private userManagementService: UserManagementService,
    private alertService: AlertService,
    private commonService: CommonService
  ) { }

  /**
   * on init
   */
  ngOnInit() {
    if (!this.authService.isPrivacyNoticeAcknowledged) {
      this.router.navigate(['/privacy-notice']);
    }
    this.loggedInUser = this.authService.getLoggedInUser();

    this.isActiveFilter = [
      { label: 'All', value: '' },
      { label: 'Yes', value: true },
      { label: 'No', value: false }
    ];

    this.cols = [
      { field: 'username', header: 'Username' },
      { field: 'email', header: 'Email' }
    ];

    this.userCols = [
      { field: 'rolename', header: 'Role Name' },
      { field: 'roledesc', header: 'Description' }
    ];

    this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
      this.modalService.dismissAll();
      if (response === 'Yes') {
        this.triggerDelete();
      }
    });

    this.getUsers();
    this.getRoles();

  }

  private getUsers() {
    this.loading = true;
    this.userManagementService.getUsers().then
      ((userList: any) => {
        this.users = userList.data;

        this.users.forEach(x => {
          x.userRoleMappingDtos.forEach(y => {
            if (x.roleList === undefined || x.roleList.length === 0) {
              x.roleList = y.role.rolename;
            } else {
              x.roleList = x.roleList + '; ' + y.role.rolename;
            }

          })
        })
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  private getRoles() {
    this.userManagementService.getRoles().then
      ((roleList: any) => {
        this.roles = roleList.data;
        // this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  handleCheckboxClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  submitUser(action) {
    if (this.selectedRoles === null || this.selectedRoles.length === 0) {
      this.roleWarningMessage = false;
      return;
    }
    const param = {
      email: this.email,
      userRoleMappingDtos: this.selectedRoles,
      username: this.username,
      isactive: this.isactive,
      DisplayUser: this.displayUser
    };
    this.loading = true;
    if (action === 'Add') {
      this.userManagementService.addUser(param).then
        ((userStatus: any) => {
          this.getUsers();
          this.modalService.dismissAll();
        }).catch(() => { this.loading = false; });

    } else if (action === 'Edit') {
      this.userManagementService.editUser(this.userid, param).then
        ((userStatus: any) => {
          this.getUsers();
          this.modalService.dismissAll();
        }).catch(() => { this.loading = false; });
    }
  }
  deleteUser(row) {
    if (row.email === this.loggedInUser.email) {
      this.alertService.warn('Self user delete is not allowed');
      return;
    }

    this.openPromptModal();

    this.userid = row.userid;
    this.username = row.username;
    this.email = row.email;
  }

  /**
   * on changes
   */
  ngOnChanges() {
    if (this.newUser) {
      this.getUsers();
      this.newUser = false;
    }
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }

  open(addUser, row, action) {
    this.mode = action;
    this.clear(row, action);
    this.modalService.open(addUser, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }
  openModal(userRole, row) {
    this.clear(row, 'Edit');
    this.modalService.open(userRole, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }

  clear(row, action) {
    if (action === 'Add') {
      this.selectedRoles = [];
      this.roleWarningMessage = true;
      this.username = '';
      this.email = '';
      this.userid = '';
      this.selectedRoles = [];
      this.isactive = true;
      this.displayUser = true;
    }
    else if (action === 'Edit') {
      this.roleWarningMessage = true;
      this.selectedUserRow = row;
      this.getSelectedUserRole(row);
      this.username = row.username;
      this.email = row.email;
      this.userid = row.userid;
      this.isactive = row.isactive;
      this.displayUser = row.DisplayUser;
    }
  }

  private getSelectedUserRole(selectedUser) {
    this.selectedRoles = [];
    selectedUser.userRoleMappingDtos.forEach(userRole => {
      this.roles.forEach(role => {
        if (userRole.roleid === role.roleid) {
          role.isSelected = true;
          this.selectedRoles.push(role);
        }
      });
    });
  }

  openPromptModal() {
    this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
  }
  triggerDelete() {
    this.loading = true;

    this.userManagementService.deleteUser(this.userid).then((userList: any) => {
      this.getUsers();
    }).catch(() => { this.loading = false; });
  }

  clearFilters() {
    this.username = null;
    this.email = null;
  }
}