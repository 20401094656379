import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { PermissionDirective } from './directives/permission.directive';
import { MaxDateValidatorDirective } from './directives/max-date.directive';
import { LoaderComponent } from './loader/loader.component';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { NgbAccordionModule, NgbTooltipModule, NgbDropdownModule, NgbPopoverModule, NgbTabsetModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportPackFilterComponent } from './report-pack-filter/report-pack-filter.component';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { ToastrModule } from 'ngx-toastr';
import { ProgressBarModule } from 'primeng/progressbar';
import { TreeTableModule } from 'primeng/treetable';
import { LoaderLandingComponent } from './loader-landing/loader-landing.component';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { NoReportPackComponent } from './no-report-pack/no-report-pack.component';
import { Ng7DynamicBreadcrumbModule } from "ng7-dynamic-breadcrumb";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PromptModalComponent } from './prompt-modal/prompt-modal.component';
import { SearchReportFilterPipe } from './directives/searchReportFilter';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { KendoGridComponent } from './kendo-grid/kendo-grid.component';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { TruncatePipe } from './directives/truncateText.pipe';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputNumberModule } from 'primeng/inputnumber';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputTextModule } from 'primeng/inputtext';
import { DataFormatPipe } from './directives/dataFormat.pipe';
import { BundleTypeFilterComponent } from './bundle-type-filter/bundle-type-filter.component';
import { ReportTreeComponent } from './report-tree/report-tree.component';
import { TrimTextDirective } from './directives/trim-text.directive';
import { ReportPackSequenceFilterComponent } from './report-pack-sequence-filter/report-pack-sequence-filter.component';
import { DynamicGridComponent } from './dynamic-grid/dynamic-grid.component';
@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        PermissionDirective,
        MaxDateValidatorDirective,
        LoaderComponent,
        ReportPackFilterComponent,
        ReportPackSequenceFilterComponent,
        LoaderLandingComponent,
        NoReportPackComponent,
        PromptModalComponent,
        SearchReportFilterPipe,
        TruncatePipe,
        DataFormatPipe,
        KendoGridComponent,
        BundleTypeFilterComponent,
        ReportTreeComponent,
        TrimTextDirective,
        DynamicGridComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        NgbAccordionModule,
        NgbModalModule,
        NgbTooltipModule,
        TableModule,
        NgbDropdownModule,
        RadioButtonModule,
        CalendarModule,
        FormsModule,
        NgbPopoverModule,
        ToastrModule.forRoot(),
        ProgressBarModule,
        TreeTableModule,
        NgbTabsetModule,
        CheckboxModule,
        DropdownModule,
        Ng7DynamicBreadcrumbModule,
        NgCircleProgressModule.forRoot(),
        GridModule,
        ExcelModule,
        TooltipModule,
        InputsModule,
        DropDownsModule,
        InputNumberModule,
        DateInputsModule,
        InputTextModule
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        HeaderComponent,
        FooterComponent,
        SidebarComponent,
        PermissionDirective,
        MaxDateValidatorDirective,
        LoaderComponent,
        ReportPackFilterComponent,
        ReportPackSequenceFilterComponent,
        LoaderLandingComponent,
        NgbTooltipModule,
        NgbDropdownModule,
        RadioButtonModule,
        CalendarModule,
        NgbPopoverModule,
        ToastrModule,
        ProgressBarModule,
        TreeTableModule,
        NgbTabsetModule,
        CheckboxModule,
        TableModule,
        DropdownModule,
        NoReportPackComponent,
        Ng7DynamicBreadcrumbModule,
        NgCircleProgressModule,
        PromptModalComponent,
        SearchReportFilterPipe,
        TruncatePipe,
        KendoGridComponent,
        GridModule,
        ExcelModule,
        TooltipModule,
        InputsModule,
        DropDownsModule,
        InputNumberModule,
        DateInputsModule,
        InputTextModule,
        DataFormatPipe,
        BundleTypeFilterComponent,
        ReportTreeComponent,
        TrimTextDirective,
        DynamicGridComponent
    ],
    entryComponents: [
        PromptModalComponent,
        ReportTreeComponent
    ],
    providers: [DecimalPipe,
        NgbActiveModal
    ]
})
export class SharedModule { }
