import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AlertService } from "src/app/core/services/alert.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AuthenticationService } from "src/app/core/security/authentication.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { RoleManagementService } from "src/app/core/security/role-management.service";
import { PermissionType } from "../models/permission.model";

@Component({
  selector: "app-dynamic-grid",
  templateUrl: "./dynamic-grid.component.html",
  styleUrls: ["./dynamic-grid.component.css"],
})
export class DynamicGridComponent implements OnInit {
  @Input() gridData: any;
  @Input() isCRUDEnabled: boolean;

  @Output() downloadResults = new EventEmitter<void>();
  @Output() saveRefDataChanges = new EventEmitter<void>();
  @Output() modelChange = new EventEmitter<{ dynamicModel: any }>();
  @Output() downloadAudit = new EventEmitter<void>();

  public loading = false;
  gridColumns: any;
  columnList: any;
  pageSize: number = 100;
  editType = "cellPopOut";
  dataDictionary: any;
  cellAudit: any;
  formChanges: any = [];
  isEditing: boolean = false;

  sortPopUpModel: any = [];
  sortColumnList: any = [];

  dynamicModel: any = { pageNumber: 1, sortList: [], filter: [], skip: 0 };

  filterList: any = [];
  filterPopUpModel: any = [];
  isInitialLoad: boolean = true;

  // RefData Editable Properties
  localDraftCells: any = [];
  cellPopOutModel: any = null;
  addPopOutModel: any = [];
  latestAudit: any = [];
  formAudit: any = [];
  user: any;
  isRefDataAdminRole: boolean = false;
  private userSubscription: Subscription;

  dataTypeDict: any = [
    { type: "string", editor: "text", decimal: 0 },
    { type: "nvarchar", editor: "text", decimal: 0 },
    { type: "varchar", editor: "text", decimal: 0 },
    { type: "text", editor: "text", decimal: 0 },
    { type: "bit", editor: "boolean", decimal: 0 },
    { type: "boolean", editor: "boolean", decimal: 0 },
    { type: "date", editor: "date", decimal: 0 },
    { type: "datetime2", editor: "date", decimal: 0 },
    { type: "number", editor: "numeric", decimal: 0 },
    { type: "decimal", editor: "numeric", decimal: 5 },
    { type: "int", editor: "numeric", decimal: 0 },
    { type: "integer", editor: "numeric", decimal: 0 },
    { type: "bigint", editor: "numeric", decimal: 0 },
    { type: "float", editor: "numeric", decimal: 0 },
  ];
  filterTypeOptions: any = {
    freeText: ["Contains"],
    text: ["Contains", "Dictionary"],
    numeric: ["Greater Than", "Less Than", "Equal To"],
    boolean: ["Equals"],
    date: ["Range", "Equal To", "Not Equal To"],
  };

  sortOrderOptions: any = [
    { sortOrder: "ASC", sortOrderText: "A to Z" },
    { sortOrder: "DESC", sortOrderText: "Z to A" },
  ];

  constructor(
    private alertService: AlertService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private authService: AuthenticationService,
    private router: Router,
    private roleService: RoleManagementService
  ) {}

  ngOnInit(): void {
    if (!this.authService.isPrivacyNoticeAcknowledged) {
      this.router.navigate(["/privacy-notice"]);
    }

    this.columnList = [];
    this.filterList = [];
    if (!this.filterPopUpModel) {
      this.filterPopUpModel = [];
    }
    this.dynamicModel = { pageNumber: 1, sortList: [], filter: [], skip: 0 };

    this.user = this.authService.getLoggedInUser();
    if (!this.user) {
      this.userSubscription = this.authService.watchUser().subscribe((user) => {
        this.user = user;
      });
    }
    this.isRefDataAdminRole = this.isCRUDEnabled && this.roleService.checkPermissionsForMenu(
      <PermissionType>"s",
      null,
      null,
      "refdata admin"
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty("gridData") &&
      this.isInitialLoad &&
      this.gridData &&
      this.gridData.columns
    ) {
      this.prepareColumns(JSON.parse(JSON.stringify(this.gridData.columns)));
    }
  }

  onPageChange(event: any) {
    this.dynamicModel.skip = event.skip;
    this.modelChange.emit(this.dynamicModel);
  }

  openPopUp(modal, size: string) {
    this.modalService.open(modal, {
      size: size,
      windowClass: "animated fadeInDown fast",
      scrollable: true,
      backdrop: "static",
    });
  }

  downloadResult() {
    this.downloadResults.emit();
  }

  prepareColumns(columns) {
    this.gridColumns = [];
    this.columnList = [];
    this.sortColumnList = [];
    this.filterList = [];

    columns.forEach((col) => {
      var editor = this.dataTypeDict.filter((x) => x.type == col.type)[0]
        .editor;

      var dc = {
        columnId: col.ColumnId,
        title: col.Text,
        field: col.dataField,
        dataType: col.type,
        editor: editor,
        editable: col.IsEditable,
        hidden: false,
        leftalign: editor === "numeric" ? true : false,
        rightalign: editor != "numeric" ? true : false,
        centeralign: false,
        isPrimaryKey: col.IsPrimaryKey,
      };
      this.columnList.push(dc);

      this.sortColumnList.push({ title: col.Text, field: col.dataField });

      var filterTypes =
        editor == "text" && col.DictGroup == null
          ? this.filterTypeOptions["freeText"]
          : this.filterTypeOptions[editor];
      var fc = {
        columnId: col.ColumnId,
        title: col.Text,
        columnName: col.dataField,
        showColumn: true,
        columnType: editor,
        enableFilter: false,
        filterType: filterTypes[0],
        filterTypeOptions: filterTypes,
        dictionary:
          editor == "boolean"
            ? [
                { value: true, title: "True" },
                { value: false, title: "False" },
              ]
            : [],
        textValue: null,
        numberValue: null,
        booleanValue: null,
        dateValue: null,
        dateLowerLimit: null,
        dateUpperLimit: null,
        dictionarySelection: [],
      };
      this.filterList.push(fc);

      if (fc.showColumn == true) this.gridColumns.push(dc);
    });

    this.filterPopUpModel = JSON.parse(JSON.stringify(this.filterList));
    this.updateFilter();
  }

  /* Sort Pop-up functions */

  cancelSortChanges() {
    this.sortPopUpModel = JSON.parse(
      JSON.stringify(this.dynamicModel.sortList)
    );
  }

  clearSortSelection() {
    this.sortPopUpModel = [];
    this.dynamicModel.sortList = [];
    this.modelChange.emit(this.dynamicModel);
  }

  confirmSortSelection() {
    this.dynamicModel.sortList = JSON.parse(
      JSON.stringify(this.sortPopUpModel)
    );
    this.modelChange.emit(this.dynamicModel);
  }

  sortGridCellClickHandler({
    sender,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }) {
    if (!isEdited) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  sortGridCellCloseHandler(args: any) {
    const { formGroup, column, dataItem } = args;

    if (!formGroup.valid) {
      args.preventDefault();
    } else {
      if (
        column.field == "columnText" &&
        dataItem["columnName"] != formGroup.value["columnName"]
      ) {
        var row = this.sortPopUpModel.filter(
          (x) => x.sortLevel == dataItem.sortLevel
        )[0];
        var col = this.sortColumnList.filter(
          (x) => x.field == formGroup.value["columnName"]
        )[0];
        row.columnName = col.field;
        row.columnText = col.title;
        this.updateSortColumnList();
      } else if (
        column.field == "sortOrderText" &&
        dataItem["sortOrder"] != formGroup.value["sortOrder"]
      ) {
        var row = this.sortPopUpModel.filter(
          (x) => x.sortLevel == dataItem.sortLevel
        )[0];
        row.sortOrder = formGroup.value["sortOrder"];
        row.sortOrderText = this.sortOrderOptions.filter(
          (x) => x.sortOrder == row.sortOrder
        )[0].sortOrderText;
      }
    }
  }

  addSortLevel() {
    var sortLevel = this.getMaxValue(this.sortPopUpModel, "sortLevel") + 1;
    if (sortLevel <= 10) {
      var rowTemplate = {
        sortLevel: sortLevel,
        sortLevelText: sortLevel == 1 ? "Sort By" : "Then By",
        columnName: "",
        columnText: "",
        sortOrder: "",
        sortOrderText: "",
      };
      this.sortPopUpModel.push(rowTemplate);
    } else
      this.alertService.info(
        "The sort level selection has reached the maximum limit!"
      );
  }

  removeSortLevel({ dataItem }) {
    this.sortPopUpModel = this.sortPopUpModel.filter(
      (x) => x.sortLevel != dataItem.sortLevel
    );
    this.sortPopUpModel.forEach((level) => {
      level.sortLevel =
        this.sortPopUpModel.findIndex((x) => x.sortLevel == level.sortLevel) +
        1;
      if (level.sortLevel == 1) level.sortLevelText = "Sort By";
    });
    this.updateSortColumnList();
  }

  updateSortColumnList() {
    this.sortColumnList = [];
    this.columnList
      .filter(
        (x) => this.sortPopUpModel.filter((y) => y.field == x.field).length == 0
      )
      .forEach((el) => {
        this.sortColumnList.push({ title: el.title, field: el.field });
      });
  }

  public createFormGroup(dataItem: any): FormGroup {
    var formGroup = dataItem;
    return this.formBuilder.group(formGroup);
  }

  getMaxValue(array: any, field: string) {
    var val = 0;
    array.forEach((el) => {
      if (el[field] > val) val = el[field];
    });
    return val;
  }

  /* Sort Pop-up functions */

  /* Filter pop-up functions */
  cancelFilterChanges() {
    this.filterPopUpModel = JSON.parse(JSON.stringify(this.filterList));
    var currentFilters = this.dynamicModel.filter;
    this.filterPopUpModel.forEach((model) => {
      var f = currentFilters.filter((x) => x.columnName == model.columnName)[0];
      if (f) {
        var dictionarySelection =
          f.textValue && f.textValue.trim() != "" ? f.textValue.split("|") : [];
        model.showColumn = f.showColumn && f.showColumn == 1 ? true : false;
        model.enableFilter = f.enableFilter;
        model.filterType = f.filterType;
        model.textValue = f.filterType == "Dictionary" ? null : f.textValue;
        model.numberValue = f.numberValue;
        model.booleanValue = f.booleanValue;
        model.dateLowerLimit = f.dateLowerLimit;
        model.dateUpperLimit = f.dateUpperLimit;
        model.dateValue = f.dateValue;
        model.dictionarySelection = model.dictionary.filter(
          (x) => dictionarySelection.indexOf(x.mappingValue) > -1
        );
      } else {
        model.showColumn = false;
      }
    });
  }

  clearFilterSelection() {
    this.filterPopUpModel = JSON.parse(JSON.stringify(this.filterList));
  }

  onFilterSwitchChange(switchValue: boolean, dataItem: any) {
    if (!switchValue) {
      var filterRow = this.filterPopUpModel.filter(
        (x) => x.columnId == dataItem.columnId
      )[0];
      filterRow.textValue = null;
      filterRow.numberValue = null;
      filterRow.booleanValue = null;
      filterRow.dateLowerLimit = null;
      filterRow.dateUpperLimit = null;
      filterRow.dateValue = null;
    }
  }

  confirmFilterSelection() {
    this.gridColumns = JSON.parse(
      JSON.stringify(
        this.columnList.filter(
          (x) =>
            this.filterPopUpModel.filter(
              (y) => y.columnName == x.field && y.showColumn == true
            ).length > 0
        )
      )
    );
    this.updateFilter();
    this.modelChange.emit(this.dynamicModel);
  }

  updateFilter() {
    this.dynamicModel.filter = [];
    this.filterPopUpModel
      .filter((x) => x.enableFilter == true || x.showColumn == true)
      .forEach((el) => {
        if (el.dictionarySelection.length > 0)
          el.textValue = el.dictionarySelection
            .map((x) => x.mappingValue)
            .join("|");
        this.dynamicModel.filter.push({
          columnName: el.columnName,
          columnType: el.columnType,
          filterType: el.filterType,
          textValue: el.textValue,
          numberValue: el.numberValue,
          booleanValue: el.booleanValue,
          dateLowerLimit: el.dateLowerLimit,
          dateUpperLimit: el.dateUpperLimit,
          dateValue: el.dateValue,
          showColumn: el.showColumn ? 1 : 0,
          enableFilter: el.enableFilter,
        });
      });
  }

  /* Filter pop-up functions */

  // RefData Edit mode

  showEditPopUp(cellPopOutModal: any, dataItem: any, column: any) {
    if (this.editType == "cellPopOut" && column.editable) {
      this.cellPopOutModel = this.getChangedCell(dataItem, column, null);
      this.modalService.open(cellPopOutModal, {
        size: "xl",
        windowClass: "animated fadeInDown fast",
        scrollable: true,
        backdrop: "static",
      });
    }
  }

  getChangedCell(dataItem, column, newValue: any) {
    var lastCellAudit = this.latestAudit.filter(
      (x) =>
        x.PrimaryKeyId == dataItem.PrimaryKeyId && x.ColumnId == column.columnId
    );
    var convertedType = this.dataTypeDict.filter(
      (x) => x.type == column.dataType
    )[0];
    // var dictionaryOptions = this.dataDictionary.filter(x => x.columnId == column.columnId);
    var cellOptions =
      convertedType.type == "boolean"
        ? [
            { value: 1, title: "True" },
            { value: 0, title: "False" },
          ]
        : [];

    var changedCell = {
      rowRefId: dataItem.PrimaryKeyId,
      column: column.field,
      clientRefId: dataItem.PrimaryKeyId,
      columnId: column.columnId,
      columnTitle: column.title,
      dataType: column.dataType,
      oldValue: dataItem[column.field],
      isNullable: column.isNullable,
      newValue: newValue
        ? newValue
        : convertedType.editor == "numeric"
        ? 0
        : convertedType.editor == "text"
        ? ""
        : null,
      cellOptions: cellOptions,
      editor: convertedType.editor,
      decimals: convertedType.decimal,
      comment: "",
      latestAudit: lastCellAudit ? lastCellAudit[0] : null,
    };

    return changedCell;
  }

  cellPopOutClose() {
    this.modalService.dismissAll();
    this.cellPopOutModel = null;
    this.cellAudit = null;
  }

  clearEditPopUp() {
    this.cellPopOutModel.newValue = 0;
    this.cellPopOutModel.comment = "";
  }

  submitCellEdit() {
    if (this.cellPopOutModel.editor == "date") {
      this.cellPopOutModel.newValue = this.datePipe.transform(
        this.cellPopOutModel.newValue,
        "yyyy-MM-dd"
      );
    }

    this.submitCellChange(this.cellPopOutModel);
    this.modalService.dismissAll();
    this.cellPopOutModel = null;
    this.cellAudit = null;
  }

  submitCellChange(changedCell) {
    //Updating changed data in form data and grid
    this.gridData.data.filter(
      (x) => x.PrimaryKeyId == changedCell.clientRefId
    )[0][changedCell.column] = changedCell.newValue;
    this.localDraftCells.push(
      changedCell.clientRefId + "_" + changedCell.column
    );

    //Updating form changes
    var prevCellChange = this.formChanges.filter(
      (x) =>
        x.columnId == changedCell.columnId &&
        x.rowRefId == changedCell.clientRefId
    )[0];
    if (prevCellChange) {
      prevCellChange.value = changedCell.newValue;
      prevCellChange.changed = true;
      prevCellChange.comment = changedCell.comment;
      prevCellChange.changeType = "U";
    } else
      this.formChanges.push({
        columnId: changedCell.columnId,
        rowRefId: changedCell.rowRefId,
        clientRefId: changedCell.clientRefId,
        oldValue: changedCell.oldValue,
        value: changedCell.newValue,
        comment: changedCell.comment,
        changeType: "U",
      });

    // if (this.ingestionLoadData.loadId != 0 && !changedCell.isNewRow)
    this.addAudit(changedCell, false);

    // if (this.formChanges.length == 30) {
    //   this.alertService.info(
    //     "There more than 30 cells changes done. Please save/submit the data"
    //   );
    // }
  }

  addAudit(changedCell: any, isDelete: boolean) {
    const changedDate = new Date();
    //Adding to audit
    var audit = this.formAudit.filter(
      (x) =>
        x.clientRefId == changedCell.clientRefId &&
        x.columnId == changedCell.columnId
    )[0];
    if (audit) {
      audit.newValue = changedCell.newValue;
      audit.comment = changedCell.comment;
      audit.changedDate = changedDate;
    } else {
      audit = {
        id: this.formAudit.length + 1,
        rowRefId: changedCell.rowRefId,
        clientRefId: changedCell.clientRefId,
        columnId: changedCell.columnId,
        rowCode: changedCell.rowCode,
        rowDescription: changedCell.headTitle,
        columnTitle: changedCell.columnTitle,
        oldValue: changedCell.oldValue,
        newValue: changedCell.newValue,
        comment: changedCell.comment,
        changedDate: changedDate,
      };
      this.formAudit.push(audit);
    }

    if (!isDelete) {
      //Updating latest audit data
      var latestCellAudit = this.latestAudit.filter(
        (x) =>
          x.RowRefId == changedCell.rowRefId &&
          x.ClientRefId == changedCell.clientRefId &&
          x.ColumnId == changedCell.columnId
      )[0];
      if (latestCellAudit) {
        latestCellAudit.OldValue = latestCellAudit.oldvalue
          ? changedCell.oldValue
          : latestCellAudit.oldvalue;
        latestCellAudit.NewValue = changedCell.newValue;
        latestCellAudit.Comment = changedCell.comment;
        latestCellAudit.ChangedBy = this.user.username;
        latestCellAudit.ChangedDate = changedDate;
      } else {
        this.latestAudit.push({
          RowCode: changedCell.rowCode,
          RowRefId: changedCell.rowRefId,
          ClientRefId: changedCell.clientRefId,
          ColumnId: changedCell.columnId,
          RowDescription: changedCell.description,
          ColumnTitle: changedCell.columnTitle,
          OldValue: changedCell.oldValue,
          NewValue: changedCell.newValue,
          Comment: changedCell.comment,
          ChangedBy: this.user.username,
          ChangedDate: changedDate,
        });
      }
    }
  }
  isValidForm() {
    if (
      this.cellPopOutModel.newValue == undefined ||
      this.cellPopOutModel.newValue == null ||
      (this.cellPopOutModel.editor == "text" &&
        this.cellPopOutModel.newValue == "") ||
      this.cellPopOutModel.comment == undefined ||
      this.cellPopOutModel.comment == "" ||
      this.cellPopOutModel.newValue == this.cellPopOutModel.oldValue
    )
      return false;
    else return true;
  }

  removeRow(data: any) {
    var row = data.dataItem;
    this.formChanges = this.formChanges.filter(
      (x) => !(x.rowRefId == row.PrimaryKeyId)
    );

    this.formChanges.push({
      columnId: row.columnId,
      rowRefId: row.PrimaryKeyId,
      clientRefId: row.PrimaryKeyId,
      value: null,
      comment: "Deleted",
      changeType: "D",
    });

    this.gridData.data = this.gridData.data.filter(
      (x) => !(x.PrimaryKeyId == row.PrimaryKeyId)
    );
  }

  undoChanges() {
    this.localDraftCells = [];
    this.formChanges = [];
    this.formAudit = [];
    this.clearSortSelection();
  }

  downloadAuditReport() {
    this.downloadAudit.emit(this.formChanges);
  }

  async saveRefDatachanges(draftVersion: boolean) {
    if (this.formChanges.length > 0) {
      this.saveRefDataChanges.emit(this.formChanges);

      this.formChanges = [];
      this.localDraftCells = [];
      this.modelChange.emit(this.dynamicModel);
    } else {
      this.alertService.info("No changes to save!");
    }
  }

  addHandler(addPopOutModal, data: any): void {
    this.getAddrowDetails();
    this.modalService.open(addPopOutModal, {
      size: "xl",
      windowClass: "animated fadeInDown fast",
      scrollable: true,
      backdrop: "static",
    });
  }

  getAddrowDetails() {
    this.addPopOutModel = [];
    this.gridColumns.forEach((element) => {
      if (!element.isPrimaryKey) {
        var convertedType = this.dataTypeDict.filter(
          (x) => x.type == element.dataType
        )[0];

        var cellOptions =
          convertedType.type == "boolean"
            ? [
                { value: 1, title: "True" },
                { value: 0, title: "False" },
              ]
            : [];

        var changedCell = {
          column: element.field,
          columnId: element.columnId,
          columnTitle: element.title,
          dataType: element.dataType,
          showField: true,
          isNullable: true,
          editor: convertedType.editor,
          decimals: convertedType.decimal,
          cellOptions: cellOptions,
          newValue:
            convertedType.editor == "numeric"
              ? 0
              : convertedType.editor == "text"
              ? ""
              : null,
          // showField: element.showField,
          // isNullable: element.isNullable,
          comment: "",
        };

        this.addPopOutModel.push(changedCell);
      }
    });
  }

  isValidAddForm() {
    this.addPopOutModel.forEach((element) => {
      if (
        element.newValue == undefined ||
        element.newValue == null ||
        (element.editor == "text" && element.newValue == "")
        // element.comment == undefined ||
        // element.comment == "" ||
        // element.newValue == element.oldValue
      )
        return false;
      else return true;
    });
  }

  clearAddPopUp() {
    this.addPopOutModel.forEach((element) => {
      element.editor == "text"
        ? (element.newValue = null)
        : (element.newValue = 0);
    });
    this.addPopOutModel.comment = "";
  }

  submitAddCells() {
    this.addPopOutModel.forEach((element) => {
      if (element.editor == "date") {
        element.newValue = this.datePipe.transform(
          element.newValue,
          "yyyy-MM-dd"
        );
      }

      this.submitNewCellValue(element);
    });
    this.saveRefDatachanges(false);
    this.modalService.dismissAll();
    this.cellPopOutModel = null;
    this.cellAudit = null;
  }

  submitNewCellValue(changedCell) {
    //Updating form changes
    var prevCellChange = this.formChanges.filter(
      (x) =>
        x.columnId == changedCell.columnId &&
        x.rowRefId == changedCell.clientRefId
    )[0];
    if (prevCellChange) {
      prevCellChange.value = changedCell.newValue;
      prevCellChange.changed = true;
      prevCellChange.comment = changedCell.comment;
      prevCellChange.changeType = "A";
    } else
      this.formChanges.push({
        columnId: changedCell.columnId,
        rowRefId: changedCell.rowRefId,
        clientRefId: changedCell.clientRefId,
        value: changedCell.newValue,
        comment: this.addPopOutModel.comment,
        changeType: "A",
      });

    // if (this.ingestionLoadData.loadId != 0 && !changedCell.isNewRow)
    this.addAudit(changedCell, false);

    // if (this.formChanges.length == 30) {
    //   this.alertService.info(
    //     "There more than 30 cells changes done. Please save/submit the data"
    //   );
    // }
  }
}
