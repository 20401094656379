import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from 'src/app/core/core.module';
import { PNPAnalysisComponent } from './pnp-analysis/component/pnp-analysis.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { SharedModule } from 'src/app/shared/shared.module';
import { PnpReportSelectionComponent } from './pnp-analysis/report-selection-component/report-selection.component';
import { PnpReportPackSelectionComponent } from './pnp-analysis/report-pack-selection-component/report-pack-selection.component';
import { PnpReportFilterComponent } from './pnp-analysis/report-filter-component/report-filter.component';
import { PnpReportComponent } from './pnp-analysis/pnp-report-component/pnp-report.component';
import { COREPAnalyticsDashboardComponent } from './corep_analytics_dashboard/corep_analytics_dashboard.component';
import { PowerBiReportsComponent } from './all-analytics/all-analytics.component';
import { PowerBiReportPanelComponent } from './all-analytics-dashboard/all-analytics-dashboard.component';

@NgModule({
  declarations: [
    PNPAnalysisComponent,
    PnpReportSelectionComponent,
    PnpReportPackSelectionComponent,
    PnpReportFilterComponent,
    PnpReportComponent,
    COREPAnalyticsDashboardComponent,
    PowerBiReportsComponent,
    PowerBiReportPanelComponent
  ],
  exports: [
    PNPAnalysisComponent,
    PowerBiReportsComponent
  ],
  imports: [
    CoreModule,
    NgDragDropModule.forRoot(),
    CommonModule,
    FormsModule,
    SharedModule
  ]
})

export class AnalyticsModule {

}