import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AlertService } from 'src/app/core/services/alert.service';
import { DashboardService } from '../../../core/services/dashboard.service';
import { SuiteService } from '../../../core/services/suite.service';
import { Subscription } from 'rxjs';
import { ReportScheduleService } from 'src/app/core/services/reportSchedule.service';
import { AuthenticationService } from 'src/app/core/security/authentication.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromptModalComponent } from 'src/app/shared/prompt-modal/prompt-modal.component';
import { CommonService } from 'src/app/core/services/common.service';

/**
 * Component
 */
@Component({
  selector: 'app-taskboard',
  templateUrl: './taskboard.component.html',
  styleUrls: ['./taskboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TaskBoardComponent implements OnInit, OnDestroy {

  headerCols: any[];
  reportApprovalList: any[];
  private dateTime = new Date();
  private yearRange = "2000:" + this.dateTime.getFullYear().toString();
  selectedRp: any;
  reportPackIdSub: Subscription;
  viewScheduleDate: Date = new Date();
  loading = false;
  scheduleReport: any;
  selectedReport: any;
  selectedPermission: any;
  loginUser: any;
  reportList: any;
  suiteList: any;
  reportPackList: any;
  filterReportSchedule: any;
  searchReport = '';

  activeDate0 = false;
  activeDate1 = false;
  activeDate2 = false;
  activeDate3 = false;
  activeDate4 = false;
  activeDate5 = false;
  activeDate6 = false;
  selectedDate: Date;

  promptResponse: Subscription;

  totalReport = 0;
  totalReportInSelectedDate = 0;
  filterDate = 'No date selected';
  private dateList: any[] = [];

  constructor(private dashboardService: DashboardService, private reportScheduleService: ReportScheduleService,
    private authService: AuthenticationService,
    private alertService: AlertService,
    private suiteService: SuiteService,
    private commonService: CommonService,
    private router: Router, private modalService: NgbModal) { }

  /**
   * on init
   */
  ngOnInit() {

    if (!this.authService.isPrivacyNoticeAcknowledged) {
      this.router.navigate(['/privacy-notice']);
    }
    this.headerCols = [
      { field: 'appLevel', header: 'Approval Level' },
      { field: 'reporter', header: 'Reporter' },
      { field: 'appDate', header: 'Approval Date' },
      { field: 'comments', header: 'Comments' },
    ];

    this.initApprovalReport();
    this.loginUser = this.authService.getLoggedInUser();
    this.dashboardService.reportId = 0;
    this.scheduleDateChange(this.viewScheduleDate);

    //this.getScheduledReport();

    this.selectedRp = this.dashboardService.selectedReportPack;

    this.reportPackIdSub = this.dashboardService.watchReportPackId().subscribe(rpId => {
      this.selectedRp = this.dashboardService.selectedReportPack;
    });

    this.promptResponse = this.commonService.watchPrompt().subscribe(response => {
      this.modalService.dismissAll();
      if (response.callFor === 'checkReportAssignedToSomeOne') {
        if (response.response === 'Yes') {
          this.updateUser(this.selectedPermission);
        }
      }
      else if (response.callFor === 'reportPackCheck') {
        if (response.response === 'Yes') { this.releaseReportPack(); }
        else {
          var rp = this.reportApprovalList.filter(function (param) { return param.ReportPackId != 0 && param.ReportPackId != null })
          if (rp.length > 0) {
            var selectedRP = this.reportPackList.filter(function (param) { return param.ReportPackID === rp[0].ReportPackId });
            if (selectedRP.length > 0) {
              this.dashboardService.setReportPack(selectedRP[0]);
            }
            else {
              this.selectedRp = null;
            }
          }
        }
      }
    });
    if (this.dashboardService.fromReport) {
      this.searchReport = this.dashboardService.formName;
      this.dashboardService.fromReport = false;
    }

  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

  scheduleDateChange(date) {

    var dayOfTheWeek = new Date(date).getDay();
    var startDateOfTheWeek: Date = date;
    startDateOfTheWeek.setDate(new Date(date).getDate() - dayOfTheWeek);
    this.selectedReport = null;
    this.getWeekDate(startDateOfTheWeek);
    //this.deactiveDateCircle();
  }

  getWeekDate(weekStartDate) {
    this.dateList = [];
    for (var i = 0; i < 7; i++) {
      var date: Date = new Date(weekStartDate);
      date.setDate(new Date(weekStartDate).getDate() + i);

      this.dateList.push(date);
    }
    this.getScheduledReport();
  }

  getScheduledReport() {
    this.loading = true;
    //this.reportApprovalList = null;
    this.reportScheduleService.getScheduledReports(this.dateList[1]).then
      ((scheduleReport: any) => {
        this.scheduleReport = scheduleReport;
        this.filterReportSchedule = this.scheduleReport.filter(function (params) { return true; });
        this.setSelectedReport();
        this.totalReport = this.scheduleReport.length;
        this.activeDate0 = false;
        this.activeDate1 = false; 
        this.activeDate2 = false;
        this.activeDate3 = false;
        this.activeDate4 = false;
        this.activeDate5 = false;
        this.activeDate6 = false;
        this.filterDate = 'No date selected';
        this.totalReportInSelectedDate = 0;

        if(this.selectedReport != null){
          this.getReportApprovalLevel(this.selectedReport);
        }else{
          this.reportApprovalList = null; 
          this.selectedRp = null; 
          this.loading = false;
        }
      }).catch(() => { this.loading = false; });
  }

  getDateList(selectedDate, selectedCircle) {
    this.deactiveDateCircle();
    this.reportApprovalList = null;
    if (selectedCircle === 0) { this.activeDate0 = true; this.reportScheduleFilter(selectedDate); }
    if (selectedCircle === 1) { this.activeDate1 = true; this.reportScheduleFilter(selectedDate); }
    if (selectedCircle === 2) { this.activeDate2 = true; this.reportScheduleFilter(selectedDate); }
    if (selectedCircle === 3) { this.activeDate3 = true; this.reportScheduleFilter(selectedDate); }
    if (selectedCircle === 4) { this.activeDate4 = true; this.reportScheduleFilter(selectedDate); }
    if (selectedCircle === 5) { this.activeDate5 = true; this.reportScheduleFilter(selectedDate); }
    if (selectedCircle === 6) { this.activeDate6 = true; this.reportScheduleFilter(selectedDate); }

  }

  deactiveDateCircle() {
    this.activeDate0 = false;
    this.activeDate1 = false;
    this.activeDate2 = false;
    this.activeDate3 = false;
    this.activeDate4 = false;
    this.activeDate5 = false;
    this.activeDate6 = false;
    this.filterDate = 'No date selected';
    this.totalReportInSelectedDate = 0;
    this.resetSelectedReport();
    this.selectedReport = null;
    this.filterReportSchedule = this.scheduleReport.filter(function (params) { return true; });
  }

  getReportApprovalLevel(report) {
    this.resetSelectedReport();
    report.IsReportSelected = true;
    this.selectedReport = report;
    this.loading = true
    this.reportScheduleService.getReportApprovalLevel(report)
      .then((data: any) => {
        let firstIndex = true;
        //Bug 979126 - Refresh task tile
          var isReportpackIsMapped = data.find(x=>x.ReportPackId != 0);
          if(isReportpackIsMapped !== undefined){
            var mappedReportPackTitle = this.reportPackList.find(x=>x.ReportPackID == isReportpackIsMapped.ReportPackId );
            if(mappedReportPackTitle.Title != report.ReportPackName){
              this.reportApprovalList = null;
              this.alertService.info('Data changed for this due date, Please refresh the date to get the latest');
              this.loading = false;
              return ;
            }
          }else if (isReportpackIsMapped === undefined && (report.ReportPackName != null && report.ReportPackName != '')){
            this.reportApprovalList = null;
              this.alertService.info('Data changed for this due date, Please refresh the date to get the latest');
              this.loading = false;
              return ;
          }
          
        
          this.reportApprovalList = data;

        this.reportApprovalList.forEach(element => {
          var approvalDate = new Date(element.ApprovalDate);
          if (approvalDate.getFullYear() < 2000) {
            element.ApprovalDate = '';
          }
        });
        
        if (report.ReportPackName != null) {
          var selectedRP = this.reportPackList.filter(function (param) { return param.Title === report.ReportPackName; });

          if (selectedRP.length > 0) {
            this.dashboardService.setReportPack(selectedRP[0]);
          }
          else if (selectedRP.length === 0) {
            //this.dashboardService.setReportPack(null);
            this.selectedRp = null;
            this.alertService.warn("Report pack " + report.ReportPackName + " is not active");
          }
        }
        else {
          this.selectedRp = null;
        }
        this.loading = false;
      }).catch(() => { this.loading = false; });
  }

  assignReportToMe(rowData) {
    this.selectedPermission = rowData;

    if (this.selectedRp === undefined || this.selectedRp === null) {
      this.alertService.error('Please select the Report pack');
      return;
    }

    // if(this.selectedReport.ReportPackName != null){
    //   var selectedRP = this.reportPackList.filter(function (param) { return param.ReportPackID === this.selectedReport.ReportPackName });
    //       if(selectedRP.length === 0){
    //         this.alertService.warn("Report pack" + this.selectedReport.ReportPackName +" is not active");
    //       }
    // }

    if (rowData.ApprovalLevel != 0) {
      var preparer = this.reportApprovalList.filter(function (param) { return param.ApprovalLevel === 0 });
      if (preparer.length === 0 || preparer[0].ReportPackId === 0) {
        this.alertService.error("Preparer is not linked the report pack for the report due");
        return;
      }
      if (preparer.length === 0 || preparer[0].ReportPackId !== this.selectedRp.ReportPackID) {
        this.alertService.error("Please reach preparer to change the report pack for the report due");
        return;
      }
    }

    var param = {
      reportShortName: rowData.ReportShortName,
      approvalLevel: rowData.ApprovalLevel,
      reportPackId: rowData.ReportPackId
    };
    this.loading = true
    this.reportScheduleService.isSubReportSignedOff(param)
      .then((data: any) => {
        if (data == true) {
          this.loading = false;
          this.alertService.warn("Cannot perform any change further, One or more Sub Report(s) is been Submitted & Approved.");
          return;
        }
        else {
          this.checkRP_IsMappedWithOtherDueDate(rowData);
        }
      }).catch(() => { this.loading = false; });
  }

  releaseReportFromMe(rowData) {
    var param = {
      reportShortName: rowData.ReportShortName,
      approvalLevel: rowData.ApprovalLevel,
      reportPackId: rowData.ReportPackId
    };
    this.loading = true
    this.reportScheduleService.isSubReportSignedOff(param)
      .then((data: any) => {
        if (data == true) {
          this.loading = false;
          this.alertService.warn("Cannot perform any change further, One or more Sub Report(s) is been Submitted & Approved.");
          return;
        }
        else {
          if (rowData.ReportPackId !== this.selectedRp.ReportPackID) {
            this.assignReportToMe(rowData);
          }
          else {
            var param = {
              UserId: this.loginUser.userid,
              ApprovalLevel: rowData.ApprovalLevel,
              ApprovalLevelDesc: rowData.ApprovalLevelDesc,
              IsActive: false,
              RSIID: rowData.RSIID
            }
            this.loading = true
            this.reportScheduleService.releaseReportFromMe(param)
              .then((data: any) => {
                this.getScheduledReport();
                //this.getReportApprovalLevel(this.selectedReport);
              }).catch(() => { this.loading = false; });
          }
        }
      }).catch(() => { this.loading = false; });
  }


  releaseReportPack() {
    var RSI = {
      RSID: this.selectedReport.RSID,
      ReportPackId: this.selectedRp.ReportPackID,
      DueDate: this.selectedReport.FallDate,
      RSIID: this.selectedPermission.RSIID,
      IsActive: true
    }
    this.loading = true;
    this.reportScheduleService.releaseReportPack(RSI)
      .then((data: any) => {
        //this.alertService.info("Report pack is released");
        this.getScheduledReport();
        //this.getReportApprovalLevel(this.selectedReport);        
      }).catch(() => { this.loading = false; });
  }


  gotoReport(row) {
    if (this.selectedRp === undefined || this.selectedRp === null) {
      this.alertService.error('Please select the Report pack');
      return;
    }

    if (this.suiteList ){
      this.loading = true;
      const suite = this.suiteList.find(obj => obj.reportSuiteAbbr === row.SuiteName);
      var report: any;
      this.dashboardService.formName = row.ReportShortName;
      this.dashboardService.reportAliasName = this.selectedReport.dynamicReport ? this.selectedReport.ReportShortName : row.ReportShortName;
      this.dashboardService.suitename = suite.reportSuiteAbbr.toLowerCase();
      this.dashboardService.reportId = this.selectedReport.ReportID;
      
      if (this.selectedReport.ParentReportID === null) {
      this.dashboardService.reportDisplayName = this.selectedReport.ReportDisplayName;
        this.dashboardService.getReportData().then(() => {
          this.loading = false;
          this.router.navigate([
            '/app/suite',
            suite.reportSuiteAbbr.toLowerCase(),
            this.dashboardService.reportDisplayName.toLowerCase()
          ]);
        }, () => { this.loading = false; });
      }
      else {
        this.dashboardService.reportAliasName = this.selectedReport.ReportShortName;       
        this.dashboardService.parentReportDisplayName = this.selectedReport.ReportDisplayName.split("|")[0].trim();
        this.dashboardService.reportDisplayName = this.selectedReport.ReportDisplayName.split("|")[1].trim();
        this.dashboardService.getReportData().then(() => {
          this.loading = false;
          this.router.navigate([
            '/app/suite',
            suite.reportSuiteAbbr.toLowerCase(),
            this.dashboardService.parentReportDisplayName.toLowerCase(),
            this.dashboardService.reportDisplayName.toLowerCase()
          ]);
        }, () => { this.loading = false; });
      }
    }
  }

  initApprovalReport() {
    this.loading = true;

    this.suiteList = this.suiteService.reportSuiteList;
    if (!this.suiteList || !this.suiteList.length) {
      this.suiteService.getSuites()
        .then((res) => {
          this.suiteList = res;
        });
    }
    this.getReportPack();
  }

  checkReportAssignedToSomeOne(rowData) {
    var param = {
      ApprovalLevel: rowData.ApprovalLevel,
      RSIID: rowData.RSIID
    }

    this.loading = true

    this.reportScheduleService.getReportScheduleInstanceUser(param)
      .then((data: any) => {
        this.loading = false;
        if (data === true) {
          this.openPromptModal('Do you want to assign the report to you?', 'checkReportAssignedToSomeOne');
        }
        else {
          this.assignReport(rowData);
        }
      }).catch(() => { this.loading = false; });
  }

  updateUser(rowData) {
    var param = {
      UserId: this.loginUser.userid,
      ApprovalLevel: rowData.ApprovalLevel,
      ApprovalLevelDesc: rowData.ApprovalLevelDesc,
      IsActive: false,
      RSIID: rowData.RSIID
    }
    this.loading = true;
    this.reportScheduleService.updateUser(param)
      .then((data: any) => {
        this.getScheduledReport();
        //this.getReportApprovalLevel(this.selectedReport);
      }).catch(() => { this.loading = false; });
  }

  openPromptModal(promptMessage, callFor) {
    var reportAssign = this.modalService.open(PromptModalComponent, { size: 'md', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
    reportAssign.componentInstance.promptMessage = promptMessage;
    reportAssign.componentInstance.promptCallFor = callFor;
  }

  getReportPack() {
    this.dashboardService.getReportPacks()
      .then(() => {
        this.reportPackList = this.dashboardService.reportPackIdList;
        // this.loading = false;
      }, () => {
        this.loading = false;
      });
  }

  checkInstanceMappedWithReportPack(rowData) {
    var param = {
      RPID: this.selectedRp.ReportPackID,
      RSID: this.selectedReport.RSID,
      DueDate: this.selectedReport.FallDate
    }

    this.loading = true

    this.reportScheduleService.checkInstanceMappedWithReportPack(param)
      .then((data: any) => {
        this.loading = false;
        if (data === false) {
          this.openPromptModal('Are you sure to change the report pack for the selected report due? This will reset the assignation of the Preparer / Approver(s).', 'reportPackCheck');
        }
        else {
          this.checkReportAssignedToSomeOne(rowData);
        }
      }).catch(() => { this.loading = false; });
  }

  assignReport(rowData) {

    var RSI = {
      RSID: this.selectedReport.RSID,
      ReportPackId: this.selectedRp.ReportPackID,
      DueDate: this.selectedReport.FallDate,
      IsActive: true
    }
    var RSIU = {
      UserId: this.loginUser.userid,
      ApprovalLevel: rowData.ApprovalLevel,
      ApprovalLevelDesc: rowData.ApprovalLevelDesc,
      IsActive: true
    }
    this.loading = true
    this.reportScheduleService.assignReportToMe(RSI, RSIU)
      .then((data: any) => {
        this.getScheduledReport();
        //this.getReportApprovalLevel(this.selectedReport);
      }).catch(() => { this.loading = false; });
  }

  reportScheduleFilter(selectedDate) {
    this.filterReportSchedule = this.scheduleReport.filter(function (params) {
      return new Date(params.FallDate).getFullYear() + '' + (new Date(params.FallDate).getMonth() + 1) + '' + new Date(params.FallDate).getDate() ===
        new Date(selectedDate).getFullYear() + '' + (new Date(selectedDate).getMonth() + 1) + '' + new Date(selectedDate).getDate()
    });
    this.totalReportInSelectedDate = this.filterReportSchedule.length;
    this.filterDate = new Date(selectedDate).getDate() + ' ' + new Date(selectedDate).toLocaleString('default', { month: 'short' });
  }
  resetSelectedReport() {
    if (this.filterReportSchedule != undefined) {
      this.filterReportSchedule.forEach(element => {
        element.IsReportSelected = false;
      });
    }
  }
  clearReportSelection() {
    this.searchReport = '';
  }

  checkRP_IsMappedWithOtherDueDate(rowData) {
    var param = {
      RPID: this.selectedRp.ReportPackID,
      RSID: this.selectedReport.RSID,
      DueDate: this.selectedReport.FallDate
    }

    this.loading = true

    this.reportScheduleService.checkRP_IsMappedWithOtherDueDate(param)
      .then((data: any) => {
        this.loading = false;
        if (data === true) {
          this.alertService.error("Report pack is already mapped with other due date.");
          return;
        }
        else {
          this.checkInstanceMappedWithReportPack(rowData);
        }
      }).catch(() => { this.loading = false; });
  }

  highlightSelectedReport() {
    if (this.selectedReport != null) {
      this.filterReportSchedule.forEach(element => {
        if (element.ReportShortName === this.selectedReport.ReportShortName && element.FallDate === this.selectedReport.FallDate) {
          element.IsReportSelected = true;
        }
      });
    }
  }

  setSelectedReport() {
    if (this.filterReportSchedule != undefined && this.selectedReport != undefined && this.selectedReport != null) {
      var selectReportFallDate = new Date(this.selectedReport.FallDate);
      this.filterReportSchedule.forEach(element => {
        var elementDate = new Date(element.FallDate);
        if (element.RSID == this.selectedReport.RSID
          && elementDate.getFullYear().toString() == selectReportFallDate.getFullYear().toString()
          && elementDate.getMonth().toString() == selectReportFallDate.getMonth().toString()
          && elementDate.getDate().toString() == selectReportFallDate.getDate().toString()
          && element.ReportShortName == this.selectedReport.ReportShortName) {
          element.IsReportSelected = true;
          this.selectedReport = element;
        }
      });
    }
  }

}

