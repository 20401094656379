import { Component, OnInit, Input, OnChanges, ViewChild, Output, EventEmitter, SimpleChanges, ViewEncapsulation, OnDestroy } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterUtils } from 'primeng/utils';
import { DatePipe } from '@angular/common'
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
    selector: 'app-kendo-grid',
    templateUrl: './kendo-grid.component.html',
    styleUrls: ['./kendo-grid.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class KendoGridComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(TooltipDirective, { static: false }) public tooltipDir: TooltipDirective;
    @Input() gridId: string;
    @Input() columns: any;
    @Input() columnGroups: any;
    @Input() gridData: any;
    @Input() showExcelExport: boolean;
    @Input() excelReportFileName: string;
    @Input() editType: string;
    @Input() latestAudit: any;
    @Input() cellAudit: any;
    @Input() blockedCells: any;
    @Input() blankCells: any;
    @Input() cellRegArtReferenceUrlList: any;
    @Input() cellDataTypes: any;
    @Input() cellDecimalPrecision: any;
    @Input() uncomputedCells: any;
    @Input() cellOptions: any;
    @Input() cellEditPropertyList: any;
    @Input() pageable: boolean;
    @Input() hasDynamicRows: boolean;
    @Input() forIFForm: boolean;
    @Input() scrollable: boolean;
    @Input() allowEdit: boolean;
    @Output() validateCellChange = new EventEmitter();
    @Output() addGridRow = new EventEmitter();
    @Output() removeGridRow = new EventEmitter();
    @Output() getCellAudit = new EventEmitter();
    @Output() allowDataEdit = new EventEmitter();
    groupedColumns: any;
    preparedColumnList: any;
    blockedCellsList: any = [];
    unblockedCellsList: any = [];
    blankCellsList: any = [];
    unBlankCellsList: any = [];
    regArtReferenceCells: any = [];
    cellPopOutModel: any = null;
    cellDataTypeJson: any = {};
    cellEditor: any;
    editableCells: any = [];
    nonEditableCells: any = [];
    cellDecimalPrecisionJson: any = {};
    uncomputedCellsList: any = [];
    computedCellsList: any = [];
    public gridView: GridDataResult;
    public pageSize = 15;
    public skip = 0;
    public auditTableCols: any[];
    modifiedDateFilters: Date[];
    private dateTime = new Date();
    private yearRange = "2000:" + this.dateTime.getFullYear().toString();
    dataTypeDict: any = [
        { type: 'string', editor: 'text', decimal: 0 },
        { type: 'text', editor: 'text', decimal: 0 },
        { type: 'boolean', editor: 'boolean', decimal: 0 },
        { type: 'date', editor: 'date', decimal: 0 },
        { type: 'number', editor: 'numeric', decimal: 0 },
        { type: 'decimal', editor: 'numeric', decimal: 5 },
        { type: 'int', editor: 'numeric', decimal: 0 },
        { type: 'integer', editor: 'numeric', decimal: 0 },
        { type: 'bigint', editor: 'numeric', decimal: 0 }
    ];

    constructor(private formBuilder: FormBuilder, private modalService: NgbModal, public datePipe: DatePipe) {
        this.allGridData = this.allGridData.bind(this);
    }

    ngOnInit() {
        if (this.columns && this.columnGroups && this.columns.length > 0 && this.columnGroups.length > 0) {
            this.prepareColumnGrouping();
        }
        if (this.blockedCells && this.blockedCells.length > 0) {
            this.prepareBlockedCellsList();
        }
        if (this.blankCells && this.blankCells.length > 0) {
            this.prepareBlankCellsList();
        }
        if (this.cellRegArtReferenceUrlList && this.cellRegArtReferenceUrlList.length > 0) {
            this.prepareCellRegArtReferenceList();
        }
        if (this.cellDecimalPrecision && this.cellDecimalPrecision.length > 0) {
            this.prepareCellDecimalPrecisionJson();
        }
        if (this.cellDataTypes && this.cellDataTypes.length > 0) {
            this.prepareCellDataTypesList();
        }
        if (this.cellEditPropertyList && this.cellEditPropertyList.length > 0) {
            this.prepareCellEditList();
        }
        if (this.uncomputedCells && this.uncomputedCells.length > 0) {
            this.prepareUncomputedCellsList();
        }
        if (this.gridData != undefined && this.gridData.length > 0) {
            this.skip = 0;
            this.loadItems();
        }

        FilterUtils['dateRangeFilter'] = (value, filter: [Date, Date]): boolean => {
            if (!filter || (!filter[0] && !filter[1]))
                return true;

            var val = new Date(value);
            var s = filter[0].getTime();
            var e;
            if (filter[1])
                e = filter[1].getTime() + (24 * 60 * 60 * 1000) - 1000;
            else e = s + (24 * 60 * 60 * 1000) - 1000;
            return val.getTime() >= s && val.getTime() <= e;
        }

        this.auditTableCols = [
            { field: 'oldValue', header: 'Old Value' },
            { field: 'newValue', header: 'New Value' },
            { field: 'comment', header: 'Comments' },
            { field: 'changedBy', header: 'Changed By' },
            { field: 'changedDate', header: 'Changed Date' }
        ];


    }

    ngOnChanges(changes: SimpleChanges) {
        if ((changes.hasOwnProperty('columns') || changes.hasOwnProperty('columnGroups')) && this.columns && this.columnGroups && this.columns.length > 0 && this.columnGroups.length > 0) {
            this.skip = 0;
            this.prepareColumnGrouping();
        }
        if (changes.hasOwnProperty('cellDecimalPrecision') && this.cellDecimalPrecision && this.cellDecimalPrecision.length > 0) {
            this.prepareCellDecimalPrecisionJson();
        }
        if (changes.hasOwnProperty('cellDataTypes') && this.cellDataTypes && this.cellDataTypes.length > 0) {
            this.prepareCellDataTypesList();
        }
        if (changes.hasOwnProperty('cellEditPropertyList') && this.cellEditPropertyList && this.cellEditPropertyList.length > 0) {
            this.prepareCellEditList();
        }
        if (changes.hasOwnProperty('gridData') && this.gridData != undefined) {
            this.loadItems();
        }
        if (changes.hasOwnProperty('blockedCells') && this.blockedCells && this.blockedCells.length > 0 && this.gridData != undefined) {
            this.prepareBlockedCellsList();
            this.skip = 0;
            this.loadItems();
        }
        if (changes.hasOwnProperty('cellRegArtReferenceUrlList') && this.cellRegArtReferenceUrlList && this.cellRegArtReferenceUrlList.length > 0) {
            this.prepareCellRegArtReferenceList();
        }
        if (changes.hasOwnProperty('blankCells') && this.blankCells && this.blankCells.length > 0 && this.gridData != undefined) {
            this.prepareBlankCellsList();
        }
        if (changes.hasOwnProperty('uncomputedCells') && this.uncomputedCells && this.uncomputedCells.length > 0) {
            this.prepareUncomputedCellsList();
        }
    }

    ngOnDestroy(): void {
        this.modalService.dismissAll();
    }

    prepareBlockedCellsList() {
        this.blockedCells.forEach(el => {
            if (el.value.toLowerCase() == 'true')
                this.blockedCellsList.push(el.boxCode);
            else if (el.value.toLowerCase() == 'false')
                this.unblockedCellsList.push(el.boxCode);
        });
    }

    prepareCellDataTypesList() {
        this.cellDataTypes.forEach(d => {
            var convertedType = this.dataTypeDict.filter(x => x.type == d.dataType)[0];
            this.cellDataTypeJson[d.boxCode] = {
                editor: convertedType.editor,
                decimals: this.cellDecimalPrecision[d.boxCode] ? this.cellDecimalPrecision[d.boxCode] : convertedType.decimal
            };
        });
    }

    prepareCellDecimalPrecisionJson() {
        this.cellDecimalPrecision.forEach(d => {
            this.cellDecimalPrecisionJson[d.boxCode] = parseInt(d.value);
        });
    }

    prepareUncomputedCellsList() {
        this.uncomputedCells.forEach(el => {
            if (el.value.toLowerCase() == 'true')
                this.uncomputedCellsList.push(el.boxCode);
            else if (el.value.toLowerCase() == 'false')
                this.computedCellsList.push(el.boxCode);
        });
    }

    prepareCellEditList() {
        this.cellEditPropertyList.forEach(el => {
            if (el.value.toLowerCase() == 'true')
                this.editableCells.push(el.boxCode);
            else if (el.value.toLowerCase() == 'false')
                this.nonEditableCells.push(el.boxCode);
        });
    }

    prepareBlankCellsList() {
        this.blankCells.forEach(el => {
            if (el.value.toLowerCase() == 'true')
                this.blankCellsList.push(el.boxCode);
            else if (el.value.toLowerCase() == 'false')
                this.unBlankCellsList.push(el.boxCode);
        });
    }

    prepareCellRegArtReferenceList() {
        this.cellRegArtReferenceUrlList.forEach(el => {
            if (el.value != '')
                this.regArtReferenceCells.push(el.boxCode);
        });
    }

    redirectToRegArt(boxCode: any) {
        const reportUrl = this.cellRegArtReferenceUrlList.find((obj) => { return obj.boxCode === boxCode; });
        if (reportUrl != undefined && reportUrl.value != '')
            window.open(reportUrl.value, '_blank');
    }

    prepareColumnGrouping() {
        this.groupedColumns = [];
        this.preparedColumnList = [];
        if (this.columns) {
            this.columns.filter(x => x.columngroup == '' || x.columngroup == null).forEach(col => {
                var convertedType = this.dataTypeDict.filter(x => x.type == col.type)[0];
                var dc = {
                    title: col.Text, field: col.dataField, dataType: col.type, editor: convertedType.editor,
                    decimals: col.decimalPrecision != null ? col.decimalPrecision : convertedType.decimal, isUncomputed: col.isUncomputed,
                    width: col.width, pinned: col.pinned ? col.pinned : false, columnId: col.IFColumnId, editable: col.editable != null ? col.editable : false,
                    hidden: col.UsedAtFrontEnd != null ? !col.UsedAtFrontEnd : false, leftalign: col.cellsalign === 'left' ? true : false,
                    rightalign: col.cellsalign === 'right' ? true : false, centeralign: !col.cellsalign || col.cellsalign === "center" ? true : false, path: '',
                    tabCode: col.TabCode, columnIdentifier: col.columnidentifier
                };
                this.groupedColumns.push(dc);
                this.preparedColumnList.push(dc);
            });
            this.groupedColumns.push({ title: 'order', field: 'order', hidden: true });
        }
        if (this.columnGroups) {
            this.columnGroups.filter(x => x.parentgroup == '' || x.parentgroup == null).forEach(cg => {
                var dcg = { title: cg.text, path: '', children: this.getChildren(cg.name, cg.text) };
                this.groupedColumns.push(dcg);
            });
        }
    }

    getChildren(cgName: string, cgPath: string) {
        var childArray = [];
        if (this.columns) {
            this.columns.filter(x => x.columngroup == cgName).forEach(col => {
                var convertedType = this.dataTypeDict.filter(x => x.type == col.type)[0];
                var dc = {
                    title: col.Text, field: col.dataField, dataType: col.type, editor: convertedType.editor, columnId: col.IFColumnId,
                    decimals: col.decimalPrecision != null ? col.decimalPrecision : convertedType.decimal, isUncomputed: col.isUncomputed,
                    width: col.width, pinned: false, editable: col.editable != null ? col.editable : false,
                    hidden: col.UsedAtFrontEnd != null ? !col.UsedAtFrontEnd : false, leftalign: col.cellsalign === 'left' ? true : false,
                    rightalign: col.cellsalign === 'right' ? true : false, centeralign: !col.cellsalign || col.cellsalign === "center" ? true : false,
                    path: cgPath, tabCode: col.TabCode, columnIdentifier: col.columnidentifier
                };
                childArray.push(dc);
                this.preparedColumnList.push(dc);
            });
        }
        this.columnGroups.filter(x => x.parentgroup == cgName).forEach(cg => {
            var dcg = { title: cg.text, path: cgPath, children: this.getChildren(cg.name, cgPath + ' - ' + cg.text) };
            childArray.push(dcg);
        });
        return childArray.length > 0 ? childArray : null;
    }

    public showTooltip(e: MouseEvent): void {
        const element = e.target as HTMLElement;
        if ((element.nodeName === 'TD' || element.nodeName === 'TH')
            && element.offsetWidth < element.scrollWidth) {
            this.tooltipDir.toggle(element);
        } else {
            this.tooltipDir.hide();
        }
    }

    cellClickHandler({ sender, column, rowIndex, columnIndex, dataItem, isEdited }) {
        var prepColumn = this.preparedColumnList.filter(x => x.field == column.field)[0];
        var cellBoxCode = prepColumn.tabCode.replace('%', dataItem.boxcode);
        if (!isEdited && ((dataItem.canadjust && prepColumn.editable && this.blockedCellsList.indexOf(cellBoxCode) == -1 && (this.blockedCellsList.indexOf(prepColumn.tabCode) == -1 || this.unblockedCellsList.indexOf(cellBoxCode) > -1) && this.nonEditableCells.indexOf(cellBoxCode) == -1)
            || (this.editableCells.indexOf(cellBoxCode) > -1))) {
            if (this.allowEdit) {
                var cellDataType = this.cellDataTypes.filter(x => x.boxCode == cellBoxCode).length > 0 ? this.cellDataTypes.filter(x => x.boxCode == cellBoxCode)[0].dataType : null;
                var convertedType = cellDataType ? this.dataTypeDict.filter(x => x.type == cellDataType)[0] : this.dataTypeDict.filter(x => x.type == prepColumn.dataType)[0];
                var dataOptions = this.cellOptions.filter(x => x.boxCode == cellBoxCode).length > 0 ? this.cellOptions.filter(x => x.boxCode == cellBoxCode) : this.cellOptions.filter(x => x.boxCode == prepColumn.tabCode);;
                var cellOptions = dataOptions.length > 0 ? dataOptions.map(x => ({ value: x.title, title: x.title })) : (convertedType.type == 'boolean' ? [{ value: 1, title: 'True' }, { value: 0, title: "False" }] : []);
                var decimalPrecision = this.cellDecimalPrecisionJson[cellBoxCode] ? this.cellDecimalPrecisionJson[cellBoxCode] : (this.cellDecimalPrecisionJson[prepColumn.tabCode] ? this.cellDecimalPrecisionJson[prepColumn.tabCode] : convertedType.decimal);
                this.cellEditor = { decimals: decimalPrecision, editor: convertedType.editor, cellOptions: cellOptions };

                sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
            }
            else this.allowDataEdit.emit();
        }
    }

    public cellCloseHandler(args: any) {
        const { formGroup, column, dataItem } = args;

        if (!formGroup.valid) {
            // prevent closing the edited cell if there are invalid values.
            args.preventDefault();
        } else if (dataItem[column.field] != formGroup.value[column.field]) {
            var changedCell = this.getChangedCell(dataItem, this.preparedColumnList.filter(x => x.field == column.field)[0], formGroup.value[column.field]);
            if (changedCell.editor == 'date' && changedCell.newValue) changedCell.newValue = this.datePipe.transform(changedCell.newValue, 'yyyy-MM-dd');
            this.validateCellChange.emit(changedCell);
        }
    }

    addRow() {
        if (this.allowEdit) {
            var rowId = this.getMaxValue(this.gridData, 'rowid') + 1;
            var rowTemplate = { rowid: rowId, clientrefid: rowId, pagename: this.gridId, canadjust: true, order: this.getMaxValue(this.gridData, 'order') + 1, isNewRow: true, boxcode: rowId };
            this.preparedColumnList.forEach(col => {
                rowTemplate[col.dataField] = col.editor == 'numeric' ? 0 : (col.editor == 'text' ? 'Test' : null);
            });
            this.addGridRow.emit(rowTemplate);
        } else this.allowDataEdit.emit();
    }

    removeRow({ dataItem }) {
        if (this.allowEdit) {
            this.removeGridRow.emit(dataItem);
        } else this.allowDataEdit.emit();
    }

    public createFormGroup(dataItem: any): FormGroup {
        var formGroup = dataItem;
        return this.formBuilder.group(formGroup);
    }

    getMaxValue(array: any, field: string) {
        var val = 0;
        array.forEach(el => {
            if (el[field] > val) val = el[field];
        });
        return val;
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.loadItems();
    }

    private loadItems(): void {
        if (!this.skip) this.skip = 0;
        this.gridView = {
            data: this.gridData.length > 0 ? (this.pageable ? this.gridData.slice(this.skip, this.skip + this.pageSize) : this.gridData) : [],
            total: this.gridData.length
        };
    }

    getChangedCell(dataItem, column, newValue: any) {
        var cellBoxCode = column.tabCode.replace('%', dataItem.boxcode);
        var lastCellAudit = dataItem.rowid ? this.latestAudit.filter(x => x.rowrefid == dataItem.rowid && x.clientrefid == dataItem.clientrefid && x.columnid == column.columnId) : this.latestAudit.filter(x => x.boxcode == cellBoxCode);
        let oldValue = !dataItem[column.field] && (this.uncomputedCellsList.indexOf(cellBoxCode) > -1 || this.uncomputedCellsList.indexOf(column.tabCode) > -1)
            ? 'Uncomputed' : dataItem[column.field];

        var cellDataType = this.cellDataTypes.filter(x => x.boxCode == cellBoxCode).length > 0 ? this.cellDataTypes.filter(x => x.boxCode == cellBoxCode)[0].dataType : null;
        var convertedType = cellDataType ? this.dataTypeDict.filter(x => x.type == cellDataType)[0] : this.dataTypeDict.filter(x => x.type == column.dataType)[0];
        var dataOptions = this.cellOptions.filter(x => x.boxCode == cellBoxCode).length > 0 ? this.cellOptions.filter(x => x.boxCode == cellBoxCode) : this.cellOptions.filter(x => x.boxCode == column.tabCode);
        var cellOptions = dataOptions.length > 0 ? dataOptions.map(x => ({ value: x.title, title: x.title })) : (convertedType.type == 'boolean' ? [{ value: 1, title: 'True' }, { value: 0, title: "False" }] : []);
        var decimalPrecision = this.cellDecimalPrecisionJson[cellBoxCode] ? this.cellDecimalPrecisionJson[cellBoxCode] : (this.cellDecimalPrecisionJson[column.tabCode] ? this.cellDecimalPrecisionJson[column.tabCode] : convertedType.decimal);

        var changedCell = {
            rowRefId: dataItem.rowid, rowCode: dataItem.boxcode, headTitle: dataItem.headtitle, column: column.field, columnIdentifier: column.columnIdentifier,
            clientRefId: dataItem.clientrefid, columnId: column.columnId, columnTitle: column.path + ' - ' + column.title, rowOrder: dataItem.order,
            tabCode: column.tabCode, dataType: column.dataType, boxCode: cellBoxCode, pageName: dataItem.pagename, oldValue: oldValue,
            newValue: newValue != null ? newValue : (convertedType.editor == 'numeric' ? 0 : (convertedType.editor == 'text' ? '' : null)), cellOptions: cellOptions,
            editor: convertedType.editor, decimals: decimalPrecision, comment: '', latestAudit: lastCellAudit ? lastCellAudit[0] : null, gridId: this.gridId,
            isNewRow: dataItem.isNewRow ? dataItem.isNewRow : false, isUncomputed: false,
        };

        return changedCell;
    }

    showEditPopUp(cellPopOutModal: any, dataItem: any, column: any) {
        var cellBoxCode = column.tabCode.replace('%', dataItem.boxcode);
        if (this.editType == 'cellPopOut' && ((column.editable && dataItem.canadjust && this.blockedCellsList.indexOf(cellBoxCode) == -1 && (this.blockedCellsList.indexOf(column.tabCode) == -1 || this.unblockedCellsList.indexOf(cellBoxCode) > -1) && this.nonEditableCells.indexOf(cellBoxCode) == -1) || this.editableCells.indexOf(cellBoxCode) > -1)) {
            if (this.allowEdit) {
                this.cellPopOutModel = this.getChangedCell(dataItem, column, null);
                this.modalService.open(cellPopOutModal, { size: 'xl', windowClass: 'animated fadeInDown fast', scrollable: true, backdrop: 'static' });
            }
            else this.allowDataEdit.emit();
        }
    }

    clearEditPopUp() {
        this.cellPopOutModel.newValue = 0;
        this.cellPopOutModel.comment = '';
    }

    submitCellEdit() {
        if (this.cellPopOutModel.editor == 'date') {
            this.cellPopOutModel.newValue = this.datePipe.transform(this.cellPopOutModel.newValue, 'yyyy-MM-dd');
        }

        this.validateCellChange.emit(this.cellPopOutModel);
        this.modalService.dismissAll();
        this.cellPopOutModel = null;
        this.cellAudit = null;
    }

    cellPopOutClose() {
        this.modalService.dismissAll();
        this.cellPopOutModel = null;
        this.cellAudit = null;
    }

    isValidForm() {
        if (this.cellPopOutModel.newValue == undefined || this.cellPopOutModel.newValue == null || (this.cellPopOutModel.editor == 'text' && this.cellPopOutModel.newValue == '')
            || this.cellPopOutModel.comment == undefined || this.cellPopOutModel.comment == ''
            || this.cellPopOutModel.newValue == this.cellPopOutModel.oldValue)
            return false;
        else return true;
    }


    closeDateRangeFilter(dateComponent: any, filters: Date[]) {
        if (filters[1]) {
            dateComponent.hideOverlay();
        }
    }

    viewChangeLog() {
        this.getCellAudit.emit(this.cellPopOutModel);
    }

    public allGridData(): ExcelExportData {
        const result: ExcelExportData = {
            data: this.gridData
        };

        return result;
    }

    public onExcelExport(e: any): void {
        const rows = e.workbook.sheets[0].rows;
        rows.filter(x => x.type == 'header').forEach(row => {
            for (let j = 0; j < row.cells.length; j++) {
                row.cells[j].hAlign = 'center';
                row.cells[j].wrap = true;
                row.cells[j].borderBottom = { color: "#c4c4cd", size: 1 };
                row.cells[j].borderTop = { color: "#c4c4cd", size: 1 };
                row.cells[j].borderRight = { color: "#c4c4cd", size: 1 };
                row.cells[j].borderLeft = { color: "#c4c4cd", size: 1 };
            }
        });
        rows.filter(x => x.type == 'data').forEach(row => {
            for (let j = 0; j < row.cells.length; j++) {
                row.cells[j].borderBottom = { color: "#656565", size: 1 };
                row.cells[j].borderTop = { color: "#656565", size: 1 };
                row.cells[j].borderRight = { color: "#656565", size: 1 };
                row.cells[j].borderLeft = { color: "#656565", size: 1 };
            }
        });
    }
}
