import { Component, OnInit } from "@angular/core";
import { AlertService } from "src/app/core/services/alert.service";
import { TreeNode } from "primeng/api";
import { BundleTypeManagementService } from "src/app/core/services/bundleTypeManagementService";
import { Router } from "@angular/router";

@Component({
  selector: "app-bundle-type-form",
  templateUrl: "./bundle-type-form.component.html",
  styleUrls: ["./bundle-type-form.component.css"],
})
export class BundleTypeFormComponent implements OnInit {
  loading = false;
  bundleType: any;
  editMode: boolean;

  reportTreeHeaders: any;
  reportTreeList: any;
  selectedNodes: TreeNode[];

  maxCharsTextArea: any = 500;

  constructor(
    private bundleTypeManagementService: BundleTypeManagementService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.bundleTypeManagementService.selectedBundleType) {
      this.backToBundleTypeManagement();
    } else {
      this.editMode = this.bundleTypeManagementService.editMode;
      this.bundleType = JSON.parse(
        JSON.stringify(this.bundleTypeManagementService.selectedBundleType)
      );

      this.reportTreeHeaders = [
        { field: "DisplayName", header: "Name" },
        { field: "Description", header: "Description" },
      ];

      this.getBundleTypeReportTreeData();
    }
  }

  backToBundleTypeManagement() {
    this.router.navigate(["/app/bundle-type-management"]);
  }

  getBundleTypeReportTreeData() {
    this.loading = true;
    this.bundleTypeManagementService
      .getBundleTypeReportTreeData()
      .then((treeList: any) => {
        this.reportTreeList = treeList;
        if (this.editMode) {
          this.populateSelectedNodes(this.reportTreeList);
          this.countSelections();
        }
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  populateSelectedNodes(reportTreeList: any) {
    let selectedReportsArray = this.selectedNodes.map((node) => node.data.Name);
    this.selectedNodes = [];
    if (selectedReportsArray.length > 0) {
      reportTreeList.forEach((suiteNode) => {
        if (suiteNode.children != null && suiteNode.children.length > 0) {
          var selectedReportNode = 0;
          suiteNode.children.forEach((reportNode) => {
            if (reportNode.children != null && reportNode.children.length > 0) {
              var selectedRtNodeCount = 0;
              reportNode.children.forEach((rtNode) => {
                if (this.editMode) {
                  if (
                    this.bundleType.reports.filter(
                      (x) =>
                        x.reportId == reportNode.data.NodeReferenceId &&
                        x.reportingTypeId == rtNode.data.NodeReferenceId
                    ).length > 0
                  ) {
                    selectedRtNodeCount = selectedRtNodeCount + 1;
                    this.selectedNodes.push(rtNode);
                  }
                } else {
                  selectedRtNodeCount = selectedRtNodeCount + 1;
                  this.selectedNodes.push(rtNode);
                }
              });
              if (reportNode.children.length == selectedRtNodeCount) {
                selectedReportNode = selectedReportNode + 1;
                this.selectedNodes.push(reportNode);
              }
            }
          });
          if (suiteNode.children.length == selectedReportNode) {
            this.selectedNodes.push(suiteNode);
          }
        }
      });
    }
  }

  clearReportFilters() {
    this.reportTreeList = this.bundleTypeManagementService.reportList.data;
  }

  clearChanges() {
    if (this.editMode) {
      this.bundleType = JSON.parse(
        JSON.stringify(this.bundleTypeManagementService.selectedBundleType)
      );
    } else
      this.bundleType = {
        bundleTypeId: 0,
        bundleTypeName: "",
        bundleDescription: "",
        reports: [],
      };
    this.populateSelectedNodes(this.reportTreeList);
    this.countSelections();
  }

  SelectAllReports(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.populateSelectedNodes(this.reportTreeList);
    this.countSelections();
  }

  countSelections() {
    this.reportTreeList.map((suiteNode) => {
      var selectedReportsCount = 0;
      if (suiteNode.children) {
        suiteNode.children.forEach((reportNode) => {
          var selectedRtsCount = 0;
          var selectedReportNodes = this.selectedNodes.filter(
            (s) => s.data.Id == reportNode.data.Id
          );
          if (selectedReportNodes && selectedReportNodes.length > 0) {
            selectedRtsCount = reportNode.children.length;
          } else {
            var selectedRts = this.selectedNodes.filter(
              (s) =>
                (s.parent && s.parent.data.Id == reportNode.data.Id) ||
                reportNode.children.filter((x) => x.data.Id == s.data.Id)
                  .length > 0
            );
            selectedRtsCount = selectedRts ? selectedRts.length : 0;
          }
          if (selectedRtsCount > 0) {
            reportNode.data.selectedCount =
              "(Selected Reporting Types: " + selectedRtsCount + ")";
            selectedReportsCount = selectedReportsCount + 1;
          } else reportNode.data.selectedCount = "";
        });
        suiteNode.data.selectedCount =
          selectedReportsCount > 0
            ? "(Selected Reports: " + selectedReportsCount + ")"
            : "";
      }
    });
  }

  findParent(data: any, id: any) {
    var parent;
    for (let node of data) {
      if (node.children) {
        if (node.children.filter((x) => x.data.Id == id).length > 0) {
          parent = node;
          break;
        } else {
          parent = this.findParent(node.children, id);
          if (parent) break;
        }
      }
    }
    return parent;
  }

  submit() {
    if (
      this.bundleType.bundleName === null ||
      this.bundleType.bundleName === ""
    ) {
      this.alertService.error("Bundle type name is a mandatory field!");
      return;
    }

    var treeList = this.reportTreeList;
    var self = this;
    var reports = [];
    if (
      this.selectedNodes !== null &&
      this.selectedNodes !== undefined &&
      this.selectedNodes.length > 0
    ) {
      this.selectedNodes
        .filter((x) => !x.children)
        .forEach(function (node) {
          var parent = node.parent;
          if (!parent) parent = self.findParent(treeList, node.data.Id);
          reports.push({
            reportId: parent.data.NodeReferenceId,
            reportName: parent.data.Name,
            reportingTypeId: node.data.NodeReferenceId,
          });
        });
      this.bundleType.reports = reports;
    } else {
      this.alertService.error("Please select atleast one report!");
      return;
    }

    this.loading = true;
    if (!this.editMode) {
      this.bundleTypeManagementService
        .addBundleType(this.bundleType)
        .then((data: any) => {
          this.bundleTypeManagementService.selectedBundleType = null;
          this.loading = false;
          this.backToBundleTypeManagement();
        })
        .catch(() => {
          this.loading = false;
        });
    } else {
      this.bundleTypeManagementService
        .updateBundleType(this.bundleType)
        .then((data: any) => {
          this.bundleTypeManagementService.selectedBundleType = null;
          this.loading = false;
          this.backToBundleTypeManagement();
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
